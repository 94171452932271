import React from 'react'
import Layout from '../components/containers/layout'
import Seo from "../components/seo/seo"
import Navbar from '../components/nav/navbar'
import Footer from '../components/nav/footer'
import Hr from '../components/hr/hr.js'
import BeforeApointment from '../components/containers/before/apointment.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/team/team.jpg")

const PrzedWizyta = () => (
  <Layout>
    <Seo title="Przed wizytą w centrum weterynaryjnym"
         description="Wszystko co trzeba wiedzieć przed wizytą u weterynarza"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "Przed wizytą" }/>
    <BeforeApointment />
    <Footer />
  </Layout>
)
export default PrzedWizyta;
