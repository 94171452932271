import React from 'react'
import { Container } from 'react-bootstrap'
import './apointment.css'


const BeforeApointment = () => (
  <Container className="text_container">
    <h4 className="font-weight-600">Na wszystkie wizyty obowiązują zapisy na daną godzinę </h4>
    <h4>
    Przychodnia na ul. Tatrzańskiej pod nr tel. 730 500 250 <br />
    Gabinet na ul. Maćka z Bogdańca pod nr tel. 727 522 422
    </h4>
    <div className="divider"></div>
    <h5 className="font-weight-600">Internistyczną/Pierwszą</h5>
    <p>
    Prosimy o przyniesienie książeczki zdrowia, badania, które miał pupil wykonywane w przeszłości oraz
można spisać na spokojnie w domu wszystkie objawy, które niepokoją nas, aby niczego nie zapomnieć,
przydatne są też zdjęcia/filmy zrobione telefonem pokazujące nietypowe zachowanie zwierzęcia co
zawsze ułatwia nam zrozumienie problemu.
    </p>
    <h5 className="font-weight-600">Pobranie krwi</h5>
    <p>
    Zwierzę powinno być 8 godzin na czczo chyba, że lekarz zaleci inaczej, woda powinna być cały czas
  dostępna.
    </p>
    <h5 className="font-weight-600">Badanie ultrasonograficzne/USG</h5>
    <p>
    Do badania prosimy aby pacjent był 8-12 godzin na czczo, w miarę możliwości z pełnym pęcherzem (kotu
  zabieramy kuwetę na 2-4 godziny przed wizytą, pies nie powinien mieć możliwości oddania moczu na
  zewnątrz)
    </p>
    <h5 className="font-weight-600">Badanie radiologiczne/RTG</h5>
    <p>
    Pupil powinien być 6-8 godzin na czczo, wodę ograniczamy na 2 godziny przed wizytą, dzięki temu
  jesteśmy w stanie w razie braku współpracy wprowadzić zwierzę w sedację i wykonać wszystkie
  potrzebne projekcje na jednej wizycie. Jeżeli były wykonywane badania krwi prosimy o ich
  przyniesienie.
    </p>
    <h5 className="font-weight-600">Badanie kału w kierunku pasożytów</h5>
    <p>
    Prosimy o zebranie niewielkiej próbki kału (wystarczy objętość ziarna fasoli) z 3 dni do jednego
pojemnika zwanego kałówką (dostępne u nas <br/> w lecznicy lub do zakupu w aptece). Do czasu
przyniesienia próbki przetrzymujemy ją w temp. 4-6C.
    </p>
    <h5 className="font-weight-600">Badanie ciśnienia</h5>
    <p>
    W naszej lecznicy jest możliwość zmierzenia ciśnienia. Staramy się ograniczyć stres pacjenta do
minimum aby badanie było miarodajne, dlatego też prosimy aby podróż pupila do nas była jak najmniej
stresująca. Po dotarciu do przychodni jeżeli mamy możliwość to zostawiamy pacjenta <br/> w samochodzie/na
zewnątrz i informujemy recepcję o przybyciu na wizytę. Jeżeli nie ma takiej możliwości wchodzimy do
lecznicy i stawiamy kota <br/> w transporterze na stojaku po prawej stronie w poczekalni jak najwyżej. Resztę
zaleceń otrzymacie Państwo już na miejscu w lecznicy. <br/> Prosimy o uniemożliwienie kontaktu z innymi
pacjentami by nie narażać zwierzę na dodatkowy stres.
    </p>
    <h5 className="font-weight-600">Wizyta onkologiczna</h5>
    <p>
    Prosimy aby pacjent przybył 12 godzin na czczo z wszystkimi wynikami badań, które były dotychczas wykonane
    (badania krwi, moczu, RTG, USG, badaniem cytologicznym/histopatologicznym) oraz historią dotychczasowego
    leczenia i listą leków jakie w danej chwili otrzymuje.
    </p>
    <h5 className="font-weight-600">Wizyta ortopedyczna</h5>
    <p>
    Pupil powinien być przygotowany jak do badania radiologicznego. Jeśli były wykonywane już w innym
miejscu badania prosimy aby zabrać je ze sobą. Pomocne zawsze są filmy nagrane z ruchem zwierzęcia
(np. gdy idzie, biegnie i skacze).
    </p>
    <h5 className="font-weight-600">Wizyta kardiologiczna</h5>
    <p>
    Na tę wizytę pacjent nie musi być specjalnie przygotowany, należy zabrać ze sobą historię leczenia, listę
  leków jakie przyjmuje pacjent oraz wyniki badań krwi.
    </p>
    <h5 className="font-weight-600">Wizyta z zwierzęciem egzotycznym</h5>
    <p>
    Prosimy o przygotowanie informacji na temat jedzenia jakie dostaje pupil, warunków utrzymania
(temperatura otoczenia, lampa UV, wilgotność), prosimy o zabranie próbki kału jeżeli to tylko możliwe.
    </p>
    <h5 className="font-weight-600">Przed zabiegiem</h5>
    <p>
    Przed zabiegiem wykonywanym w znieczuleniu ogólnym zwierzę zawsze musi odbyć wizytę
kwalifikacyjną i mieć zrobione badania krwi. <br/> W przypadku niektórych operacji konieczne jest wykonanie zdjęć RTG i
badanie kardiologiczne. W dniu zabiegu przyjmujemy pacjenta po 12 godz. głodówce oraz 2-4 godziny bez
pobierania wody. Zostaje przyjęty na szpital gdzie w spokoju jest przygotowany do zabiegu oraz
pozostaje tam do całkowitego wybudzenia. Jeżeli nie ma innych zaleceń ze strony lekarza tego samego
dnia może opuścić szpital i wrócić do domu (zwykle po 6-10 godzinach od przyjęcia).
    </p>
    <h5 className="font-weight-600">Po zabiegu</h5>
    <p>
    Przez pierwsze 12 godzin po zabiegu nie podajemy nic do jedzenia i do picia, zapewniamy zwierzęciu
ciche, ciepłe i spokojne miejsce (ograniczamy kontakt z resztą domowników by niepotrzebnie nie
niepokoili), zalecane jest pozostawić przygaszone światło, ze względu na odczuwanie światłowstrętu przez
większość pacjentów.
    </p>
  </Container>
);

export default BeforeApointment;
